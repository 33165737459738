import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import useUpdateUserRelationships from '../../util/hooks/useUpdateUserRelationships';
import { UserContext } from '../../context/user/context';
import { getWithExpiry } from '../../util/auth/drupal';
import { ToolTip } from '../global/ToolTip';

const FormArtists = ({ allArtists }) => {
  const [tooltip, setTooltip] = useState(false);
  const { state, userUpdateArtistFollowAll } = useContext(UserContext);
  const { user } = state;
  const {
    relationshipMessage,
    updatingRelationships,
    setRelationshipInfo,
  } = useUpdateUserRelationships(user.id);

  useEffect(() => {
    if (relationshipMessage && updatingRelationships) {
      setTooltip(true);
      setTimeout(() => setTooltip(false), 3000);
    }
  }, [relationshipMessage, updatingRelationships, setTooltip]);

    // force refresh updates the 
  // formik key to keep state consistent when 
  // reloading the page
  const [ forceRefresh, setForceRefresh ] = useState(0);

  useEffect(() => {
    setForceRefresh(+forceRefresh+1);
  }, [user])

  return (
    <>
      <h4 className="t-large">Your favourite artists</h4>
      <div className="spacer"></div>
      <Formik
        key={`user-artists-form-${forceRefresh}`}
        initialValues={{
          field_artist_of_interest:
            user &&
            user.field_artist_of_interest &&
            user.field_artist_of_interest.length > 0
              ? user.field_artist_of_interest
              : [],
        }}
        onSubmit={async (values) => {
          const data = values.field_artist_of_interest.map((id) => ({
            type: 'node--artist',
            id,
          }));

          const relationships = {
            field_artist_of_interest: {
              data,
            },
          };

          setRelationshipInfo({
            relationships,
            token: getWithExpiry('csrf-token'),
          });
          userUpdateArtistFollowAll(values.field_artist_of_interest);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="all-favourite-artists-wrapper">
              <div className="all-favourite-artists">
                {allArtists.map(({ node: artist }) => {
                  return (
                    <div className="control check" key={artist.drupal_id}>
                      <label className="checkbox">
                        <Field
                          value={artist.drupal_id}
                          type="checkbox"
                          name="field_artist_of_interest"
                        />
                        <span className="checkmark"></span>
                        <span className="t-medium">
                          {`${artist.field_lastname}, ${artist.field_firstname}`}
                        </span>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="spacer" style={{ position: 'relative' }}>
              <ToolTip isActive={tooltip}>{relationshipMessage}</ToolTip>
            </div>

            <button
              type="submit"
              disabled={isSubmitting || updatingRelationships}
              className={`button btn-large btn-center btn-space-large`}
            >
              {isSubmitting || updatingRelationships
                ? `Submitting`
                : `Save Artists`}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default FormArtists;
