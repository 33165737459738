import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import useUpdateUserRelationships from '../../util/hooks/useUpdateUserRelationships';
import { UserContext } from '../../context/user/context';
import { getWithExpiry } from '../../util/auth/drupal';
import { ToolTip } from '../global/ToolTip';

const FormLocations = ({ allLocations }) => {
  const [tooltip, setTooltip] = useState(false);
  const { state, userUpdateLocationsAll } = useContext(UserContext);
  const { user } = state;
  const {
    relationshipMessage,
    updatingRelationships,
    setRelationshipInfo,
  } = useUpdateUserRelationships(user.id);

  useEffect(() => {
    if (relationshipMessage && updatingRelationships) {
      setTooltip(true);
      setTimeout(() => setTooltip(false), 3000);
    }
  }, [relationshipMessage, updatingRelationships, setTooltip]);

  // force refresh updates the
  // formik key to keep state consistent when
  // reloading the page
  const [forceRefresh, setForceRefresh] = useState(0);

  useEffect(() => {
    setForceRefresh(+forceRefresh + 1);
  }, [user]);

  return (
    <>
      <h4 className="t-large">Locations</h4>
      <div className="spacer"></div>
      <Formik
        key={`user-locations-form-${forceRefresh}`}
        initialValues={{
          field_locations_ref:
            user &&
            user.field_locations_ref &&
            user.field_locations_ref.length > 0
              ? user.field_locations_ref
              : [],
        }}
        validate={(values) => {
          const errors = {};
          if (values.field_locations_ref.length === 0) {
            errors.field_locations_ref = 'One selection required.';
          }
          return errors;
        }}
        onSubmit={async (values) => {
          const data = values.field_locations_ref.map((id) => ({
            type: 'node--gallery',
            id,
          }));

          const relationships = {
            field_locations_ref: {
              data,
            },
          };

          setRelationshipInfo({
            relationships,
            token: getWithExpiry('csrf-token'),
          });
          userUpdateLocationsAll(values.field_locations_ref);
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form>
            <div
              className="all-locations"
              role="group"
              aria-labelledby="checkbox-group"
            >
              {allLocations.map(({ node: location }, i) => {
                return (
                  <div className="control check" key={location.drupal_id}>
                    <label className="checkbox">
                      <Field
                        value={location.drupal_id}
                        type="checkbox"
                        name="field_locations_ref"
                      />
                      <span className="checkmark"></span>
                      <span className="t-medium">
                        {location.title.split(', ')[1]}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="spacer" style={{ position: 'relative' }}>
              <ToolTip isActive={tooltip}>{relationshipMessage}</ToolTip>
              <ToolTip isActive={errors.field_locations_ref}>
                {errors.field_locations_ref}
              </ToolTip>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`button btn-large btn-center btn-space-large`}
            >
              {isSubmitting ? `Submitting` : `Save Locations`}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default FormLocations;
