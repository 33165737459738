import React, { useContext, useState, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { UserContext } from '../../context/user/context';
import { ToolTip } from '../global/ToolTip';
import useUpdateUserRelationships from '../../util/hooks/useUpdateUserRelationships';
import { getWithExpiry } from '../../util/auth/drupal';

const MailchimpSubscription = ({isSubmit}) => {
  const [tooltip, setTooltip] = useState(false);
  const { state, userUpdateMailchimpStatus } = useContext(UserContext);
  const { field_mailchimp_status } = state.user;
  const [mcStatus, setMcStatus] = useState(field_mailchimp_status);

  // force refresh updates the 
  // formik key to keep state consistent when 
  // reloading the page
  const [ forceRefresh, setForceRefresh ] = useState(0);
  useEffect(() => {
    setForceRefresh(+forceRefresh+1);
  }, [state.user])

  const {
    relationshipMessage,
    updatingRelationships,
    setRelationshipInfo,
  } = useUpdateUserRelationships(state.user.id);

  useEffect(() => {
    if (relationshipMessage && updatingRelationships) {
      setTooltip(true);
      setTimeout(() => setTooltip(false), 3000);
    }
  }, [relationshipMessage, updatingRelationships, setTooltip]);

  const submitMailChimpStatus = () => {
    // sub
    let id = 'e0ce27e0-4949-4b4b-bd9d-7df278028624';

    // unsub
    if (!mcStatus) {
      id = '5d888d91-9347-46b2-9258-1503825d348b';
    }

    const relationships = {
      field_mailchimp_status: {
        data: {
          type: 'taxonomy_term--mailchimp_status',
          id,
        },
      },
    };

    setTimeout(() => {
      setRelationshipInfo({
        relationships,
        token: getWithExpiry('csrf-token'),
      })
    }, [1500])
    userUpdateMailchimpStatus(!field_mailchimp_status);
  }

  useEffect(() => {
    if (isSubmit) {
      submitMailChimpStatus();
    }
  }, [isSubmit]);

  return (
    <form>
        <fieldset>
          <div className="control check">
            <label className="checkbox">
              <input 
                type="checkbox" 
                name="agreement" 
                checked={mcStatus}
                onChange={(e) => setMcStatus(!mcStatus)}
              />
              <span className="checkmark"></span>
              <span className="t-medium">
                I agree to receive newsletters from Loch Gallery.
              </span>
            </label>
          </div>
        </fieldset>
        <div style={{ position: 'relative' }}>
          <ToolTip isActive={tooltip}>{relationshipMessage}</ToolTip>
        </div>
    </form>
  );
};
export default MailchimpSubscription;
