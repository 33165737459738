import React, { useState, useEffect } from 'react';
import { ToolTip } from '../global/ToolTip';
import { drupalPasswordReset } from '../../util/auth/register'
import { getWithExpiry } from '../../util/auth/drupal'

export default function({}) {

    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(null);

    const handleFormSubmission = (e) => {
        e.preventDefault();
        if (!submit) {
            setSubmit(true);
            setLoading(true);
        }
    }

    useEffect(() => {
        if (error) {
            setTimeout(() => setError(false), 3000);
        }
        if (success) {
            setTimeout(() => setSuccess(false), 3000);
        }
    }, [error, setError, success, setSuccess]);

    // register on submit
    useEffect(() => {

        async function fetchPassReset() {
            // invoke early
            setSubmit(false);

            const mail = await getWithExpiry('name');
            // reset password request
            const res = await drupalPasswordReset(mail);

            // there's an error, display it
            if (res.error) {
                setError(res.msg);
                setSuccess(null);
            }
            // no error, do stuff
            else {
                setSuccess(true);
                setError(false);
            }
            setLoading(false);
        }

        if (submit) {
            // if submit, fetch
            fetchPassReset();
        }

    }, [submit]);

    return (
        <React.Fragment>
            <div className="input-item">
                {/*
                <label className="t-small" htmlFor="user-password">
                    Change Password
                </label>
                */}
                {/* <input
                    name="user-password"
                    id="user-password"
                    type="password"
                    placeholder="password"
                /> */}
            </div>
            <button
                type="submit"
                disabled={loading}
                className={`button btn-large btn-center btn-space-large`}
                onClick={handleFormSubmission}
            >
                {loading
                ? `Submitting`
                : `Request New Password`}
            </button>
            <div className="spacer"></div>
            {error ? (
                <div className="spacer" style={{ position: 'relative' }}>
                    <ToolTip isActive={true}>{error}</ToolTip>
                </div>
            ) : null}
            {success ? (
                <div className="spacer" style={{ position: 'relative' }}>
                    <ToolTip isActive={true}>Details on resetting your password have been sent to your email</ToolTip>
                </div>
            ) : null}
        </React.Fragment>
    )
}