import React, { useContext, useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import useUpdateUserRelationships from '../../util/hooks/useUpdateUserRelationships';
import { UserContext } from '../../context/user/context';
import { getWithExpiry } from '../../util/auth/drupal';
import { ToolTip } from '../global/ToolTip';

const FormServices = ({ allServices }) => {
  const [tooltip, setTooltip] = useState(false);
  const { state, userUpdateServices } = useContext(UserContext);
  const { user } = state;
  const {
    relationshipMessage,
    updatingRelationships,
    setRelationshipInfo,
  } = useUpdateUserRelationships(user.id);

  useEffect(() => {
    if (relationshipMessage && updatingRelationships) {
      setTooltip(true);
      setTimeout(() => setTooltip(false), 3000);
    }
  }, [relationshipMessage, updatingRelationships, setTooltip]);

  // force refresh updates the 
  // formik key to keep state consistent when 
  // reloading the page
  const [ forceRefresh, setForceRefresh ] = useState(0);

  useEffect(() => {
    setForceRefresh(+forceRefresh+1);
  }, [user])

  return (
    <>
      <h4 className="t-large">Services that interest you</h4>
      <div className="spacer"></div>
      <Formik
        key={`user-services-form-${forceRefresh}`}
        initialValues={{
          field_services_that_interest_you:
            user &&
            user.field_services_that_interest_you &&
            user.field_services_that_interest_you.length > 0
              ? user.field_services_that_interest_you
              : [],
        }}
        onSubmit={async (values) => {
          const data = values.field_services_that_interest_you.map((id) => ({
            type: 'taxonomy_term--services',
            id,
          }));

          const relationships = {
            field_services_that_interest_you: {
              data,
            },
          };

          setRelationshipInfo({
            relationships,
            token: getWithExpiry('csrf-token'),
          });
          userUpdateServices(values.field_services_that_interest_you);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <div className="all-services">
              {allServices.map(({ node: service }) => {
                return (
                  <div className="control check" key={service.drupal_id}>
                    <label className="checkbox">
                      <Field
                        value={service.drupal_id}
                        type="checkbox"
                        name="field_services_that_interest_you"
                      />
                      <span className="checkmark"></span>
                      <span className="t-medium">{`${service.name}`}</span>
                    </label>
                  </div>
                );
              })}
            </div>

            <div className="spacer" style={{ position: 'relative' }}>
              <ToolTip isActive={tooltip}>{relationshipMessage}</ToolTip>
            </div>

            <button
              type="submit"
              disabled={isSubmitting || updatingRelationships}
              className={`button btn-large btn-center btn-space-large`}
            >
              {isSubmitting || updatingRelationships
                ? `Submitting`
                : `Save Services`}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default FormServices;
