import { useState, useEffect } from 'react';
import { baseUrl, getWithExpiry } from '../auth/drupal';
const apiBase = `${baseUrl}/jsonapi`;

/**
 * Update user attribute properities hook
 * @param {String} id - User Id
 * @returns{ {updatingAttributes: Boolean, attributeMessage: String, setUserAttributes: Function} }
 */
const useUpdateUserAddress = (id = '') => {
  const [userAttributes, setUserAttributes] = useState({
    attributes: {},
    token: '',
  });
  const [attributeMessage, setAttributeMessage] = useState('');
  const [updatingAttributes, setUpdatingAttributes] = useState(false);

  useEffect(() => {
    const updateUserAddress = async () => {
      try {
        setUpdatingAttributes(true);
        const { attributes } = userAttributes;
        const body = {
          data: {
            type: 'paragraph--address',
            id,
            attributes,
          },
        };

        const res = await fetch(`${apiBase}/paragraph/address/${id}`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': await userAttributes.token,
          },
          credentials: 'include',
          body: JSON.stringify(body),
        });

        if (res.ok === true) {
          setAttributeMessage('Settings saved');
        } else {
          setAttributeMessage(
            "Settings didn't save, please try again. If the issue persists please reload the page or contact the webmaster"
          );
        }
        setUpdatingAttributes(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (
      userAttributes.attributes &&
      Object.keys(userAttributes.attributes).length !== 0 &&
      userAttributes.token !== ''
    ) {
        updateUserAddress();
    }
  }, [userAttributes, id]);

  return { updatingAttributes, attributeMessage, setUserAttributes };
};

export default useUpdateUserAddress;
