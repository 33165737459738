import { useState, useEffect } from 'react';
import { baseUrl, getWithExpiry } from '../auth/drupal';
const apiBase = `${baseUrl}/jsonapi`;

/**
 * Update user relationship properities hook
 * @param {String} id - User id
 * @returns { {relationshipMessage: String, updatingRelationships: Boolean, setRelationshipInfo: Function} }
 */
const useUpdateUserRelationships = (id = '') => {
  const [userRelationship, setRelationshipInfo] = useState({
    relationships: {},
    token: '',
  });
  const [relationshipMessage, setRelationshipMessage] = useState('');
  const [updatingRelationships, setUpdatingRelationships] = useState(false);

  useEffect(() => {
    const updateUserRelationships = async () => {
      try {
        let csrf = await getWithExpiry('csrf-token');
        setUpdatingRelationships(true);
        const body = {
          data: {
            type: 'node--contact',
            id,
            relationships: userRelationship.relationships,
          },
        };

        const req = {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'X-CSRF-Token': csrf,
          },
          credentials: 'include',
          body: JSON.stringify(body),
        };

        const res = await fetch(`${apiBase}/node/contact/${id}`, req);

        if (res.ok === true) {
          setRelationshipMessage('Settings saved');
        } else {
          setRelationshipMessage(
            "Settings didn't save, please try again. If the issue persists please reload the page or contact the webmaster"
          );
        }
        setUpdatingRelationships(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (
      userRelationship.relationships &&
      Object.keys(userRelationship.relationships).length !== 0 &&
      userRelationship.token !== ''
    ) {
      updateUserRelationships();
    }
  }, [userRelationship]);

  return { relationshipMessage, updatingRelationships, setRelationshipInfo };
};

export default useUpdateUserRelationships;
