import React, { useState, useContext } from 'react'
import Modal from '../global/Modal';
import {drupalDeleteUser, clearLocalStorage} from '../../util/auth/drupal'
import { UserContext } from '../../context/user/context';
import { navigate } from "gatsby"

export default function () {

    const [modalActive, setModalActive] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [reasonForDelete, setReasonForDelete] = useState('');
    const [password, setPassword] = useState('');
    const [stepTwo, setStepTwo] = useState(false);
    const [errors, setErrors] = useState({})

    const { userLogout } = useContext(UserContext);


    const confirmDeleteStage1 = () => {
        // verify that we want to delete the account
        if (confirmDelete) {
            setStepTwo(true)
            // reset errors
            setErrors({})
        } else {
            // throw error
            setErrors({
                confirm: true,
            })
        }
    }

    const deleteAccount = async () => {

        // authenticate the user with a password
        if (password) {
            // authenticate
            const response = await drupalDeleteUser(password);

            if (response === true) {
                // no errors, user deleted

                // update errors
                setErrors({});
                // notify the admins (TBD)

                // remove local storage variables
                clearLocalStorage();

                // call logout from context
                userLogout()

                // close the modal window
                setModalActive(false);

                // alert the user that it was successful
                alert('Your account data has been deleted.');

                // redirect the user to the login page
                navigate("/user/login/")

            } else if (response.error === true) {
                // there was an error, update the message
                setErrors({
                    password: response.message
                })
            }
        }
    }

    return (
        <React.Fragment>
            <button
                // className="t-large text-button"
                className="button btn-large btn-center btn-space-large"
                onClick={() => setModalActive(true)}
            >
                DELETE ACCOUNT
            </button>
            <Modal
                active={modalActive}
                onClose={() => setModalActive(false)}
            >
                <h1 className="t-xlarge t-heading t-uppercase sub-section-title">
                    Delete My Account
                </h1>
                {!stepTwo ? (
                    <React.Fragment>
                        <div className="input-item">
                            <p><input type="checkbox" value={confirmDelete} onChange={(e) => setConfirmDelete(e.target.value)} /> Are you sure you'd like to delete your account?</p>
                            {errors.confirm && (<span className="error">Please check the box to confirm that you would like to delete your account</span>)}
                        </div>
                        <div className="input-item">
                            <label className="t-small" htmlFor="user-password">
                                Tell us why you're leaving
                            </label>
                            <textarea
                                value={reasonForDelete}
                                onChange={(e) => setReasonForDelete(e.target.value) }
                                className="large-input"
                            />
                        </div>
                        <p>Please note that by deleting your account, all of your information and account will be permanently deleted from our database and you will no longer be able to log in.</p>
                        <button
                            className={`button btn-large btn-center btn-space-large`}
                            onClick={confirmDeleteStage1}
                        >
                            I confirm I want to delete my account
                        </button>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="input-item">
                            <label className="t-small" htmlFor="user-password">
                                Please confirm your password to delete your account
                            </label>
                            <div>
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            {errors.password && (<span className="error">{errors.password}</span>)}
                        </div>
                        <button
                            className={`button btn-large btn-center btn-space-large`}
                            onClick={deleteAccount}
                        >
                            Delete My Account
                        </button>
                    </React.Fragment>
                )}
            </Modal>
        </React.Fragment>
    )
}