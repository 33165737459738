/* eslint-disable react/jsx-indent */
import React, { useState, useContext } from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/Layout';
import UserHeading from '../../components/global/UserHeading';
import { UserContext } from '../../context/user/context';
import useAuthGate from '../../util/hooks/useAuthGate';
import SEO from '../../components/SEO';
import FormUserInfo from '../../components/user/FormUserInfo';
import FormArtists from '../../components/user/FormArtists';
import FormLocations from '../../components/user/FormLocations';
import FormAddress from '../../components/user/FormAddress';
import FormServices from '../../components/user/FormServices';
import FormArtworkTypes from '../../components/user/FormArtworkTypes';
import DeleteAccount from '../../components/user/DeleteAccount';
import ChangePassword from '../../components/user/ChangePassword';
import MailchimpSubscription from '../../components/user/MailchimpSubscription';

const UserAccountPage = ({ data }) => {
  const [device, setDevice] = useState('mobile');

  const { state } = useContext(UserContext);
  const { user } = state;
  useAuthGate('/user/login');

  const { edges: allLocations } = data.allLocations;
  const { edges: allTypes } = data.allTypes;
  const { edges: allServices } = data.allServices;
  const { edges: allArtists } = data.allArtists;

  const name = `${user.field_firstname ? user.field_firstname : ''} ${
    user.field_lastname ? user.field_lastname : ''
  }`;

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      footerMenu={data.footer_menu}
      menu={data.allMenuMenuLink}
      locations={data.locations.edges}
    >
      <SEO title="Account" />
      <UserHeading account={true} username={name} />
      <main className="user-page account">
        <section className="grid-section locations grid-content-container">
          <h1 className="t-xlarge t-heading t-uppercase sub-section-title">
            My Account
          </h1>
          <div className="columns">
            <div className="column is-8">
              <FormUserInfo />
            </div>
            {/* <div className="column is-4">
              <MailchimpSubscription />
            </div> */}
          </div>
        </section>

        {/* 
        !HIDING: because we're not using it.
        !LEAVING IT HERE: because maybe we turn it back on later.
        <section className="grid-section locations grid-content-container">
          <div className="columns">
            <div className="column is-8">
              <FormAddress />
            </div>
          </div>
        </section> */}

        <section className="grid-section locations grid-content-container">
          <div className="columns">
            <div className="column is-8">
              <FormLocations allLocations={allLocations} />
            </div>
          </div>
        </section>

        <section className="grid-section types grid-content-container">
          <div className="columns">
            <div className="column is-8">
              <FormArtworkTypes allTypes={allTypes} />
            </div>
          </div>
        </section>

        <section className="grid-section services grid-content-container">
          <div className="columns">
            <div className="column is-8">
              <FormServices allServices={allServices} />
            </div>
          </div>
        </section>

        <section className="grid-section favourite-artists grid-content-container">
          <div className="columns">
            <div className="column is-8">
              <FormArtists allArtists={allArtists} />
            </div>
          </div>
        </section>

        <section className="grid-section locations grid-content-container">
          <div className="columns">
            <div className="column is-8">
              <ChangePassword />
              <div className="spacer"></div>
              <DeleteAccount />
            </div>
          </div>
          <div className="spacer"></div>
        </section>
      </main>
    </Layout>
  );
};

export default UserAccountPage;

export const UserAccountPageQuery = graphql`
  query UserAccountPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    allTypes: allTaxonomyTermArtworkType {
      edges {
        node {
          name
          drupal_id
        }
      }
    }
    allServices: allTaxonomyTermServices {
      edges {
        node {
          name
          drupal_id
        }
      }
    }
    allArtists: allNodeArtist(
      sort: { fields: field_lastname }
      filter: { status: { eq: true } }
    ) {
      edges {
        node {
          path {
            alias
          }
          title
          relationships {
            field_artist_listing_image {
              uri {
                url
              }
            }
          }
          field_firstname
          field_lastname
          drupal_id
        }
      }
    }
    allLocations: allNodeGallery(
      sort: { fields: title }
      filter: { title: { regex: "/^(Loch Gallery)/" } }
    ) {
      edges {
        node {
          drupal_id
          title
        }
      }
    }
  }
`;
